<template>
   <div>
      <h2 class="content-block">Слоты погрузочных/разгрузочных работ</h2>
      <DxPivotGrid
         id="pivotGrid"
         :ref="pivotGridRefKey"
         :allow-sorting-by-summary="true"
         :allow-sorting="true"
         :allow-filtering="true"
         :allow-expand-all="true"
         :show-borders="true"
         :data-source="dataSource"
         @cell-click="onCellClick"
      >
         <DxFieldChooser :enabled="true" />
      </DxPivotGrid>
      <DxPopup
         :width="600"
         :height="400"
         :title.sync="popupTitle"
         :visible.sync="popupVisible"
         @showing="onShowing"
         @hiding="onHiding"
         @shown="onShown"
      >
         <DxDataGrid
            :ref="gridRefKey"
            :width="560"
            :height="300"
            @row-inserting="onRowInserting"
            @row-updating="onRowUpdating"
            @row-removing="onRowRemoving"
         >
            <DxEditing
               :allow-updating="true"
               :allow-deleting="true"
               :allow-adding="true"
            />

            <DxColumn data-field="region" />
            <DxColumn data-field="city" />
            <DxColumn data-field="amount" data-type="number" />
            <DxColumn data-field="date" data-type="date" />
         </DxDataGrid>
      </DxPopup>
   </div>
</template>

<script>
import DxPivotGrid, { DxFieldChooser } from "devextreme-vue/pivot-grid";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
/*tar import { DxPopup } from "devextreme-vue/popup";*/
/*tar import { DxDataGrid, DxColumn, DxEditing } from "devextreme-vue/data-grid";*/

const pivotGridRefKey = "pivotGrid";
const gridRefKey = "grid";

const dataSource = new PivotGridDataSource({
   load: () => {
      let url =
         "http://10.10.10.180/data/dd.php?proc=get_slots&params=" +
         `'{"contr_n":"${window.contr_n}"}'`;
      return fetch(url)
         .then((response) => response.json())
         .then((data) => ({
            data: data,
         }))
         .catch(() => {
            throw new Error("Data Loading Error");
         });
   },
   fields: [
      {
         caption: "Время",
         dataField: "TIME",
         area: "row",
      },
      {
         caption: "Дата",
         dataField: "DT",
         dataType: "string",
         area: "column",
      },
      {
         caption: "Склад",
         dataField: "STORE_N",
         area: "column",
      },
      {
         caption: "Ворота",
         dataField: "ADDR",
         area: "column",
      },
      {
         caption: "Операция",
         dataField: "OPER",
         dataType: "string",
         summaryType: "max",
         area: "data",
         columnAutoWidth: false,
      },
   ],
});

export default {
   data() {
      console.log(dataSource.store);
      return {
         gridRefKey,
         drillDownDataSource: {},
         pivotGridRefKey,
         dataSource: dataSource,
         popupTitle: "",
         popupVisible: false,
      };
   },
   computed: {
      pivotGrid: function () {
         return this.$refs[pivotGridRefKey].instance;
      },
      grid: function () {
         return this.$refs[gridRefKey].instance;
      },
   },
   methods: {
      onCellClick(e) {
         if (e.area === "data") {
            var pivotGridDataSource = e.component.getDataSource(),
               rowPathLength = e.cell.rowPath.length,
               rowPathName = e.cell.rowPath[rowPathLength - 1],
               popupTitle =
                  (rowPathName ? rowPathName : "Total") + " Drill Down Data";

            this.drillDownDataSource =
               pivotGridDataSource.createDrillDownDataSource(e.cell);

            this.popupTitle = popupTitle;
            this.popupVisible = true;
         }
      },
      /*onShowing() {
         var that = this;
         this.drillDownDataSource
            .store()
            .load()
            .done(function (items) {
               that.grid.option("dataSource", {
                  store: new ArrayStore({
                     key: that.data.key(),
                     data: items,
                  }),
               });
            });
      },       */
      onHiding() {
         this.pivotGrid.getDataSource().reload();
      },
      onShown() {
         this.grid.updateDimensions();
      },
      onRowInserting(e) {
         this.data.insert(e.data);
      },
      onRowUpdating(e) {
         this.data.update(e.key, e.newData);
      },
      onRowRemoving(e) {
         this.data.remove(e.key);
      },
      handleValueChange() {
         this.pivotGrid.getDataSource().reload();
         //this.dataPivot.refresh();
         //console.log(dataGridInstance);
      },
   },
   components: {
      DxPivotGrid,
      DxFieldChooser,
   },
};
</script>
<style>
.settings {
   display: flex;
   padding: 15px;
}

.settings .column .field {
   padding: 5px 0;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.settings .column .field .label {
   padding-right: 10px;
}

.settings .column .field .value {
   width: 155px;
}

.dx-datagrid .dx-row td {
   padding: 0;
   cellpadding: 0;
}
</style>
